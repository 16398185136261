// import React, { useState } from "react";
// import {
//   Layout,
//   Typography,
//   Card,
//   Button,
//   Splitter,
//   Form,
//   Input,
//   Select,
//   message,
// } from "antd";
// import axios from "axios";
// // import StripeCheckout from "react-stripe-checkout";
// // import { Payments } from '@square/web-sdk';
// import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
// import { GiftFilled } from "@ant-design/icons";
// const { Header, Content } = Layout;
// const { Title, Text } = Typography;

// const options = ["USD", "GBP", "EUR"];

// const Donations = () => {
//   const [amount, setamount] = useState(0);
//   const [currency, setcurrency] = useState("");
//   // const handleFinish = async (values) => {
//   //   try {
//   //     // Send API request
//   //     console.log(values);

//   //     const response = await axios.post("http://127.0.0.1:5200/api/payments", {
//   //       amount: values.amount,
//   //       currency: values.currency,
//   //     });

//   //     // Show success message if request is successful
//   //     message.success("Payment successful!");
//   //     console.log("Response:", response.data);
//   //   } catch (error) {
//   //     // Handle errors
//   //     message.error("Payment failed!");
//   //     console.error("Error:", error);
//   //   }
//   // };

//   // const makePayment = async (token) => {
//   //   try {
//   //     const body = {
//   //       token,
//   //       product: {
//   //         amount: token.amount,
//   //         currency: token.currency,
//   //       },
//   //     };
//   //     const headers = { "Content-Type": "application/json" };
//   //     const response = await axios.post(
//   //       "http://127.0.0.1:5200/api/payments",
//   //       { body: JSON.stringify(body) },
//   //       {
//   //         headers: headers,
//   //       }
//   //     );
//   //     console.log("response:", response);
//   //     console.log("Status:", response.status);
//   //   } catch (error) {
//   //     // If the request fails
//   //     if (error.response) {
//   //       // Server responded with a status code other than 2xx
//   //       console.error("Error Response:", error.response.data);
//   //       console.error("Status:", error.response.status);
//   //       console.error("Headers:", error.response.headers);
//   //       // You can handle specific status codes here
//   //     } else if (error.request) {
//   //       // Request was made but no response received
//   //       console.error("Error Request:", error.request);
//   //     } else {
//   //       // Something else happened in making the request
//   //       console.error("Error Message:", error.message);
//   //     }
//   //     // Show an error message to the user
//   //   }
//   // };

//   return (
//     <Layout style={{ minHeight: "100vh" }}>
//       <Header style={{ backgroundColor: "#001529", color: "white" }}>
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             marginBottom: "20px",
//           }}
//         >
//           <GiftFilled
//             style={{ fontSize: "20px", color: "white", marginRight: "26px" }}
//           />
//           <h2 style={{ color: "white", margin: "0" }}>Donate Us</h2>
//         </div>

//         {/* <Title level={2} style={{ color: 'white', margin: '0' }}>Donate Us</Title> */}
//       </Header>
//       <Splitter
//         style={{
//           height: 400,
//           boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
         
//         }}
//       >
//         <Splitter.Panel resizable={false}>
//           <Content style={{ padding: "10px", backgroundColor: "#f0f2f5" ,}}>
//             <Form
//               name="wrap"
//               labelCol={{
//                 flex: "110px",
//               }}
//               labelAlign="left"
//               labelWrap
//               wrapperCol={{
//                 flex: 1,
//               }}
//               colon={false}
//               style={{
//                 maxWidth: 600,
//               }}
//               // onFinish={handleFinish}
//             >
//               <Form.Item
//                 label="Amount"
//                 name="amount"
//                 rules={[{ required: true }]}
//               >
//                 <Input
//                   maxLength={10} // Restrict to 10 characters
//                   placeholder="Enter up to 10 digits"
//                   value={amount} // Bind input value to state
//                   onChange={(e) => {
//                     const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
//                     setamount(value); // Update state
//                   }}
//                 />
//               </Form.Item>

//               <Form.Item
//                 label="Currency"
//                 name="currency"
//                 rules={[
//                   { required: true, message: "Please select an option!" },
//                 ]}
//               >
//                 <Select
//                   placeholder="Please select an option"
//                   onSelect={(e) => {
//                     setcurrency(e); // Update state
//                   }}
//                 >
//                   {options.map((option, index) => (
//                     <Select.Option key={index} value={option}>
//                       {option}
//                     </Select.Option>
//                   ))}
//                 </Select>
//               </Form.Item>

//               <Form.Item label=" ">
//                 <PaymentForm
//                   applicationId={process.env.REACT_APP_SQUARE_APP_ID}
//                   locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
//                   cardTokenizeResponseReceived={async (token, buyer) => {
//                     try {
//                     const response = await axios.post(
//                       "http://127.0.0.1:5200/api/payments",
//                       {
//                         body: JSON.stringify({
//                           sourceId: token.token,
//                           product: {
//                             currency: currency,
//                             amount: amount,
//                           },
//                         }),
//                       },
//                       {
//                         headers: { "Content-Type": "application/json" },
//                       }
//                     );
//                     alert(JSON.stringify(response.data, null, 2));
//                   } catch (error) {
//                      console.error('Payment failed:', error);
//                      alert('Payment failed. Please try again.'); }
//                   }}
//                 >
//                   <CreditCard />
//                 </PaymentForm>
//                 {/* <StripeCheckout
//                   stripeKey={process.env.REACT_APP_KEY}
//                   token={makePayment}
//                   name="Donate Us"
//                   amount={amount * 100}
//                   currency={currency}
//                 >
//                   <Button type="primary" htmlType="submit">
//                     Pay
//                   </Button>
//                 </StripeCheckout> */}
//               </Form.Item>
//             </Form>
//           </Content>
//         </Splitter.Panel>
//         <Splitter.Panel resizable={false}>
//           <Content
//             style={{
//               padding: "10px",
//               backgroundColor: "#f0f2f5",
//               display: "flex",
//               justifyContent: "center",
//             }}
//           >
//             <Card
//               style={{
//                 width: "100%",
//                 maxWidth: "600px", // Limit the max width for larger screens
//                 margin: "auto",
//                 padding: "20px",
//               }}
//               bodyStyle={{
//                 padding: "20px",
//               }}
//             >
//               <Title
//                 level={3}
//                 style={{ textAlign: "center", fontSize: "1.5rem" }}
//               >
//                 Support Our Cause
//               </Title>
//               <Text
//                 style={{
//                   fontSize: "1rem",
//                   textAlign: "center",
//                   display: "block",
//                 }}
//               >
//                 Your contributions help us continue our work and make a
//                 difference in the community. Thank you for your support!
//               </Text>

//               <Title
//                 level={4}
//                 style={{ marginTop: "20px", fontSize: "1.2rem" }}
//               >
//                 Bank Details
//               </Title>

//               <Text strong>Bank Name: </Text>
//               <Text>Wise Payments Limited</Text>
//               <br />

//               <Text strong>Account Name: </Text>
//               <Text>
//                 Foundation for Rehabilitation & Education for Special Children
//                 Ltd
//               </Text>
//               <br />

//               <Text strong>Account Number: </Text>
//               <Text>61967513</Text>
//               <br />

//               <Text strong>IBAN: </Text>
//               <Text>GB29 TRWI 2308 0161 9675 13</Text>
//             </Card>
//           </Content>
//         </Splitter.Panel>
//       </Splitter>
//     </Layout>
//   );
// };

// export default Donations;











import React, { useState } from 'react';
import { Layout, Form, Input, Select, Button, Card, Typography, Modal, Alert } from 'antd';
import { GiftFilled } from '@ant-design/icons';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import axios from 'axios';

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const Donations = () => {
  const [amount, setAmount] = useState('');
  // const [currency, setCurrency] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [alert, setAlert] = useState(null);

  const options = ['USD', 'GBP', 'EUR'];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ backgroundColor: '#001529', color: 'white' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <GiftFilled style={{ fontSize: '20px', color: 'white', marginRight: '26px' }} />
          <h2 style={{ color: 'white', margin: '0' }}>Donate Us</h2>
        </div>
      </Header>
      <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
        <Card style={{ width: '100%', maxWidth: '600px', margin: '20px 0', padding: '20px' }}>
          <Form
            name="wrap"
            labelCol={{ flex: '110px' }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            style={{ maxWidth: 600 }}
          >
            <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
              <Input
                maxLength={10}
                placeholder="Enter up to 10 digits"
                value={amount}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setAmount(value);
                }}
              />
            </Form.Item>

            {/* <Form.Item label="Currency" name="currency" rules={[{ required: true, message: 'Please select an option!' }]}>
              <Select placeholder="Please select an option" onSelect={(e) => setCurrency(e)}>
                {options.map((option, index) => (
                  <Select.Option key={index} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}

            <Form.Item label=" ">
              <PaymentForm
                applicationId={process.env.REACT_APP_SQUARE_APP_ID}
                locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
                cardTokenizeResponseReceived={async (token, buyer) => {
                  try {
                    const response = await axios.post(
                      'https://fresh-backend-d4c6dc8628a3.herokuapp.com/api/payments',
                      {
                        sourceId: token.token,
                        product: {
                          currency: 'GBP',
                          amount: amount,
                        },
                      },
                      {
                        headers: { 'Content-Type': 'application/json' },
                      }
                    );
                    setAlert({
                      message: `Payment successful! Transaction ID: ${response.data.payment.id}`,
                      type: 'success',
                    });
                    showModal();
                  } catch (error) {
                    console.error('Payment failed:', error);
                    setAlert({
                      message: 'Payment failed. Please try again.',
                      type: 'error',
                    });
                    showModal();
                  }
                }}
              >
                <CreditCard />
              </PaymentForm>
            </Form.Item>
          </Form>
        </Card>
        <Card style={{ width: '100%', maxWidth: '600px', margin: '20px 0', padding: '20px' }}>
          <Title level={3} style={{ textAlign: 'center', fontSize: '1.5rem' }}>
            Support Our Cause
          </Title>
          <Text style={{ fontSize: '1rem', textAlign: 'center', display: 'block' }}>
            Your contributions help us continue our work and make a difference in the community. Thank you for your support!
          </Text>
          <Title level={4} style={{ marginTop: '20px', fontSize: '1.2rem' }}>
            Bank Details
          </Title>
          <Text strong>Bank Name: </Text>
          <Text>Wise Payments Limited</Text>
          <br />
          <Text strong>Account Name: </Text>
          <Text>Foundation for Rehabilitation & Education for Special Children Ltd</Text>
          <br />
          <Text strong>Account Number: </Text>
          <Text>61967513</Text>
          <br />
          <Text strong>IBAN: </Text>
          <Text>GB29 TRWI 2308 0161 9675 13</Text>
        </Card>
      </Content>
      <Modal title="Payment Status" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        {alert && <Alert message={alert.message} type={alert.type} showIcon />}
      </Modal>
    </Layout>
  );
};

export default Donations;
